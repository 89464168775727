import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['table', 'template']

  initialize() {
    this.tbody = this.tableTarget.tBodies[0]

    if (this.tbody.rows.length == 0) {
      this.add()
    }
  }

  add() {
    const timestamp = new Date().getTime()
    const selector = 'input, select, textarea, checkbox, radio'

    this.tbody.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML)

    this.tbody.rows[this.tbody.rows.length - 1].querySelectorAll(selector).forEach(function(input) {
      input.name = input.name.replace('[]', '[' + timestamp + ']')
    })

    // Initialize "bootstrap-select" plugin
    $('.bootstrap-select').selectpicker()
  }

  remove(event) {
    for (var i = 0; i < event.path.length; i++) {
      if (event.path[i].tagName == 'TR') {
        event.path[i].classList.add('d-none')
        event.path[i].querySelector('input[name*="_destroy"]').value = 1
        break
      }
    }

    if (this.tbody.querySelectorAll('tr:not(.d-none)').length == 0) {
      this.add()
    }
  }
}
