import { Controller } from 'stimulus'
import dom from 'helpers/dom'

export default class extends Controller {
  initialize() {
    this.createIntersectionElement()
  }

  connect() {
    window.addEventListener('scroll', this.processIntersectionEntries.bind(this))
  }

  disconnect() {
    window.removeEventListener('scroll', this.processIntersectionEntries.bind(this))
  }

  createIntersectionElement() {
    this.intersectionElement = document.createElement('div')
    this.intersectionElement.classList.add('sticky-top-observer')

    this.element.parentElement.insertBefore(this.intersectionElement, this.element)
  }

  processIntersectionEntries() {
    this.element.classList.toggle('sticky-top-fixed', !dom.inViewport(this.intersectionElement))
  }
}
