import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= this.data.get('position')) {
        this.element.classList.add(this.data.get('class'))
      } else {
        this.element.classList.remove(this.data.get('class'))
      }
    })
  }
}
