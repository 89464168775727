/**
 * helpers/dom.js
 */
export default {
  /**
   * Shows an alert to the user with Bootstrap markup
   * @param {string} context
   * @param {string} message
   */
  alert: function(context, message) {
    const alertContainer = document.getElementById('alertContainer')

    let alert = `
      <div class="alert alert-${context} fade show" role="alert">
        ${message}
        <button type="button" class="close" data-dismiss="alert">
          <i class="icon-times"></i>
        </button>
      </div>
    `

    if (alertContainer === null) {
      alert = `<div class="alert-container" id="alertContainer">${alert}</div>`

      document.body.insertAdjacentHTML('beforeend', alert)
    } else {
      alertContainer.insertAdjacentHTML('afterbegin', alert)
    }

    setTimeout(function() {
      $(alertContainer).find('.alert:last-child').alert('close')
    }, 2000);
  },
  /**
   * Check if the element is visible in viewport
   * @param {object} element
   */
  inViewport(element) {
    const bounding = element.getBoundingClientRect()

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
}
