// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('@rails/ujs').start()
require('turbolinks').start()

// Third-party libraries
import 'bootstrap'
import 'bootstrap-select'

// Stimulus controllers
import 'controllers'

// Custom initializers
import 'initializers/bootstrap'
import 'initializers/selectpicker'

import 'utils/live_search'
import 'utils/range_slider'
import 'utils/video_modal'

// Custom overrides
import 'overrides/confirm'

import 'utils/collapsible_fields'
