import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', this.submitContentForm)
  }

  disconnect() {
    this.element.removeEventListener('click', this.submitContentForm)
  }

  submitContentForm(event) {
    const form = document.getElementsByClassName('content')[0].getElementsByTagName('form')[0]
    const button = event.currentTarget

    // Save form parameters
    const currentAction = form.action
    const currentTarget = form.target

    event.preventDefault()

    button.disabled = true

    if (button.name && button.value) {
      const input = document.createElement('input')

      input.setAttribute('type', 'hidden')
      input.setAttribute('name', button.name)
      input.setAttribute('value', button.value)

      form.appendChild(input)
    }

    if (button.dataset.action !== undefined) {
      form.action = button.dataset.action
    }

    if (button.dataset.target !== undefined) {
      form.target = button.dataset.target
    }

    if (button.dataset.remote == 'false') {
      form.submit()
      form.action = currentAction
      form.target = currentTarget
      button.disabled = false
    } else {
      Rails.fire(form, 'submit')
    }

    // waits for submition so it doesnt change action before files upload is ready
    form.addEventListener('ajax:success', function(event) {
      // Reset form parameters
      form.action = currentAction
      form.target = currentTarget
    })
  }
}
