import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['country', 'region']

  connect() {
    this.countryTarget.addEventListener('change', this.toggleRegion.bind(this))
  }

  disconnect() {
    this.countryTarget.removeEventListener('change', this.toggleRegion.bind(this))
  }

  toggleRegion() {
    if (this.countryTarget.value === this.data.get('portugal')) {
      this.regionTarget.classList.remove('d-none')
    } else {
      this.regionTarget.classList.add('d-none')
      this.regionTarget.getElementsByTagName('select')[0].value = ''
    }
  }
}
