document.addEventListener('turbolinks:load', function () {

  let url = $(".live-search-form").attr('action');
  const paramsUrl = window.location.href.split("?")[1];
  if (paramsUrl !== undefined) { url = url + '?' + paramsUrl; }

  // Search
  $(".live-search-form input").keyup(function () {
    const formData = $(".live-search-form").serialize();
    $.get(url, formData, null, "script");
    return false;
  });

  // Order by
  $(".live-search-form select").on('change', function () {
    const formData = $(".live-search-form").serialize();
    $.get(url, formData, null, "script");
    return false;
  });

  // Statistics form
  $(".live-dates-form input").change(function () {
    let url = $(".live-dates-form").attr('action');
    const formData = $(".live-dates-form").serialize();
    $.get(url, formData, null, "script");
    return false;
  });
});
