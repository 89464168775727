document.addEventListener('turbolinks:load', function () {
  // Changes the default settings for bootstrap-select
  $.fn.selectpicker.Constructor.DEFAULTS.liveSearchNormalize = true
  $.fn.selectpicker.Constructor.DEFAULTS.size = 10
  $.fn.selectpicker.Constructor.DEFAULTS.windowPadding = [85, 0, 0, 0] // 85 is the navbar height

  // Translations for portuguese
  const translationsPT = {
    noneSelectedText: 'Nenhuma opção selecionada',
    noneResultsText: 'Sem resultados contendo {0}',
    countSelectedText: 'Selecionado {0} de {1}',
    maxOptionsText: ['Limite ultrapassado (máx. {n} {var})', 'Limite de seleções ultrapassado (máx. {n} {var})', ['itens', 'item']],
    multipleSeparator: ', ',
    selectAllText: 'Selecionar Tudo',
    deselectAllText: 'Desmarcar Todos'
  }

  // Translations for english
  const translationsEN = {
    noneSelectedText: 'Nothing selected',
    noneResultsText: 'No results match {0}',
    countSelectedText: function (numSelected, _numTotal) {
      return (numSelected == 1) ? '{0} item selected' : '{0} items selected';
    },
    maxOptionsText: function (numAll, numGroup) {
      return [
        (numAll == 1) ? 'Limit reached ({n} item max)' : 'Limit reached ({n} items max)',
        (numGroup == 1) ? 'Group limit reached ({n} item max)' : 'Group limit reached ({n} items max)'
      ];
    },
    selectAllText: 'Select All',
    deselectAllText: 'Deselect All',
    multipleSeparator: ', '
  }

  // Sets translations
  if (document.documentElement.lang == 'pt-PT') {
    $.fn.selectpicker.defaults = translationsPT
  } else {
    $.fn.selectpicker.defaults = translationsEN
  }

  $('.bootstrap-select').selectpicker()
})

// Destroy selectpicker before turbolinks cache
document.addEventListener('turbolinks:before-cache', function () {
  $('.bootstrap-select').selectpicker('destroy').addClass('bootstrap-select')
})
