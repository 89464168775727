document.addEventListener('turbolinks:load', function () {
  const filterForm = document.getElementById('filterForm');
  const price_range = document.getElementById('price_range');
  const price_range_output = document.getElementById('price_range_output');
  const price_range_bubble = document.getElementById('price_range_bubble');
  const price_custom = document.getElementById('price_custom');
  const price_any = document.getElementById('price_any');

  price_range && price_range.addEventListener("input", function () {
    set_bubble_position();
  });

  filterForm && filterForm.addEventListener("reset", function () {
    price_any.checked = true;
  });

  if (price_custom && price_custom.checked === true) {
    price_range.disabled = false
    set_bubble_position();
  }

  price_custom && price_custom.addEventListener("click", function () {
    price_range.disabled = false
    set_bubble_position();
  });

  price_any && price_any.addEventListener("click", function () {
    price_range.disabled = true
    price_range_bubble.style.display = 'none';
  });

  function set_bubble_position() {
    var handleSize = parseFloat(window.getComputedStyle(document.querySelector('p')).fontSize);
    var rangeSize = price_range.offsetWidth;
    var maxHandleX = rangeSize - handleSize;
    var percentage = (price_range.value - price_range.min) / (price_range.max - price_range.min);
    var position = percentage * maxHandleX + 25 - 3*percentage;

    price_range_bubble.style.left = position + 'px';
    price_range_output.textContent = price_range.value;
    price_range_bubble.style.display = 'block';
  }
});
