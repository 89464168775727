import { Controller } from 'stimulus'
import DirtyForm from 'dirty-form'

export default class extends Controller {
  connect() {
    new CustomDirtyForm(this.element, {
      message: 'O formulário tem alterações por guardar.<br>Tem a certeza que deseja sair desta página?'
    })
  }
}

class CustomDirtyForm extends DirtyForm {
  constructor(form, options = {}) {
    super(form, options)
  }

  // Overrides this method from DirtyFrom library
  setFormHandlers() {
    // Handle submit
    window.addEventListener('submit', this.handleSubmit.bind(this))
    this.form.addEventListener('submit', this.handleSubmit.bind(this))

    // Handle leaving page
    // window.onbeforeunload = () => {
    //   if (this.isDirty) {
    //     return this.message
    //   }
    // }

    if (typeof Turbolinks !== 'undefined') {
      let target = undefined

      document.addEventListener('turbolinks:click', (event) => {
        target = event.target
      })

      document.addEventListener('turbolinks:before-visit', (event) => {
        document.getElementById('confirmButton').innerHTML = 'Sair'

        if (this.isDirty && !Rails.confirm(this.message, target)) {
          event.preventDefault()
        } else {
          this.isDirty = false
        }
      })
    }
  }
}
