document.addEventListener("turbolinks:load", function () {
  const modalZoom = document.getElementById("videoModalZoom");
  const videoModalZoom = document.querySelector("#videoModalZoom video");

  const videoModalIntro = document.querySelector("#videoModalIntro video");
  const modalIntro = document.getElementById("videoModalIntro");

  $(modalZoom).on("hidden.bs.modal", function () {
    videoModalZoom.pause();
    videoModalZoom.currentTime = 0;
    videoModalZoom.load();
  });

  $(modalIntro).on("hidden.bs.modal", function () {
    videoModalIntro.pause();
    videoModalIntro.currentTime = 0;
    videoModalIntro.load();
  });
});
