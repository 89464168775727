import bsCustomFileInput from 'bs-custom-file-input'

// Add inline style to bootsrap Tooltips and Popovers whiteList
$.fn.tooltip.Constructor.Default.whiteList['*'].push('style')

document.addEventListener('turbolinks:load', function () {
  $('[data-toggle="tooltip"]').tooltip({
    container: 'body',
    boundary: 'viewport'
  })

  $('[data-toggle="popover"]').popover({
    container: 'body',
    boundary: 'viewport'
  })

  if (typeof $().colorpicker == 'function') {
    $('[data-toggle="colorpicker"]').colorpicker()
  }

  bsCustomFileInput.init()

  // Preview of images for "custom-file" component
  Array.from(document.getElementsByClassName('custom-file-input')).forEach(function (input) {
    if (input.parentElement.classList.contains('custom-file-preview')) {
      input.addEventListener('change', function () {
        const reader = new FileReader()
        const file = input.files[0]

        // Remove text from label
        input.nextElementSibling.innerHTML = ''

        reader.onload = function (event) {
          // Get loaded data and render preview
          input.parentElement.style.backgroundImage = "url('" + event.target.result + "')"
        }

        // Read the image file as a data URL
        if (file.type.match('image.*')) {
          reader.readAsDataURL(file)
        }
      })
    }
  })

  $('[id^="message-preview-"]').on('show.bs.modal', function () {
    $('#message-body').html($('trix-editor').val())
  })

  // Show question modal after load
  $('#questionModal').modal('show')
})
