import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['price', 'discount', 'priceWithDiscount', 'quantity', 'vatTotalPrice']

  initialize() {
    this.formatOptions = {
      currency: 'EUR',
      style: 'currency'
    }
  }

  calculate() {
    const price = this.priceTarget.value
    const discount = this.discountTarget.value

    if (!isNaN(price) && !isNaN(discount) && price > 0 && discount > 0) {
      this.priceWithDiscountTarget.value = this.format(price - (discount * (price / 100)))
    } else {
      this.priceWithDiscountTarget.value = ''
    }
  }

  updateTotal() {
    const quantity = this.quantityTarget.value
    const totalPrice = this.data.get('totalprice')

    const orderSubtotal = document.getElementById('order-subtotal')
    const orderTotal = document.getElementById('order-total')
    const orderVat = document.getElementById('order-vat')

    const subtotal = orderSubtotal.dataset.subtotal - totalPrice
    const total = orderTotal.dataset.total - totalPrice
    const vat = orderVat.dataset.vat - this.data.get('totalvat')

    let allProductsPrice = this.data.get('productprice') * quantity
    let allProductsVat = this.data.get('productvat') * quantity

    this.priceWithDiscountTarget.textContent = this.format(allProductsPrice)
    this.vatTotalPriceTarget.textContent = this.format(allProductsVat)

    this.data.set('totalprice', allProductsPrice)
    this.data.set('totalvat', allProductsVat)

    orderSubtotal.textContent = this.format(subtotal + parseFloat(this.data.get('totalprice')))
    orderSubtotal.dataset.subtotal = subtotal + parseFloat(this.data.get('totalprice'))

    orderTotal.textContent = this.format(total + parseFloat(this.data.get('totalprice')))
    orderTotal.dataset.total = total + parseFloat(this.data.get('totalprice'))

    orderVat.textContent = this.format(vat + parseFloat(this.data.get('totalvat')))
    orderVat.dataset.vat = vat + parseFloat(this.data.get('totalvat'))
  }

  format(value) {
    return value.toLocaleString(this.application.element.lang, this.formatOptions)
  }
}
