import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['parent', 'child']

  connect() {
    this.parentTarget.addEventListener('change', this.toggleChildren.bind(this))

    this.childTargets.forEach(el => {
      el.addEventListener('change', this.toggleParent.bind(this))
    })
  }

  disconnect() {
    this.parentTarget.removeEventListener('change', this.toggleChildren.bind(this))

    this.childTargets.forEach(el => {
      el.addEventListener('change', this.toggleParent.bind(this))
    })
  }

  toggleParent() {
    if (this.childTargets.every(el => el.checked == true)) {
      this.parentTarget.checked = true
    } else {
      this.parentTarget.checked = false
    }
  }

  toggleChildren() {
    this.childTargets.forEach(el => {
      el.checked = this.parentTarget.checked
    })
  }
}
