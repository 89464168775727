Rails.confirm = function(message, element) {
  const modal = document.getElementById('confirmModal')
  const button = document.getElementById('confirmButton')
  const checkbox = document.getElementById('published')

  const buttonContext = element.dataset.confirmButtonContext
  const buttonText = element.dataset.confirmButtonText

  const defaultButtonText = button.innerHTML

  modal.querySelector('.modal-body p').innerHTML = message

  if (buttonContext !== undefined) {
    button.classList.remove('btn-danger')
    button.classList.add('btn-' + buttonContext)
  }

  if (buttonText !== undefined) {
    button.innerHTML = buttonText
  }

  // TODO: This should be out of this method with "addEventLister"
  $(button).off('click').on('click', function(event) {
    event.preventDefault()

    const railsConfirm = Rails.confirm

    Rails.confirm = function() {
      return true
    }

    element.click()

    Rails.confirm = railsConfirm
  })



  $(modal).modal('show')

  // Set default context and text for confirm button
  $(modal).on('hidden.bs.modal', function() {
    // Prevents checkbox from checking when canceling action
    // TODO:
    if (checkbox) {
      checkbox.checked = !checkbox.checked
    }

    button.classList.remove('btn-' + buttonContext)
    button.classList.add('btn-danger')

    button.innerHTML = defaultButtonText
  })

  return false
}
