import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['table', 'totalWithoutVat', 'vat', 'totalWithVat']

  initialize() {
    this.formatOptions = {
      currency: 'EUR',
      style: 'currency'
    }
    if (this.hasTableTarget) {
      this.calculate()
    }
  }

  calculate() {
    let totalWithoutVat = 0
    let vat = 0
    let totalWithVat = 0

    let price
    let quantity
    let vatRate

    this.tableTarget.tBodies[0].querySelectorAll('tr:not(.d-none)').forEach(function(tr) {
      price = parseFloat(tr.querySelector('input[name*="price"]').value)
      quantity = parseInt(tr.querySelector('input[name*="quantity"]').value)
      vatRate = parseFloat(tr.querySelector('select[name*="vat_rate_id"] option:checked').innerText)

      if (!isNaN(price) && !isNaN(quantity) && !isNaN(vatRate)) {
        totalWithoutVat += price * quantity
        vat += (price * quantity) * (vatRate / 100)
        totalWithVat += price * quantity + ((price * quantity) * (vatRate / 100))
      }
    })

    this.totalWithoutVatTarget.value = this.format(totalWithoutVat)
    this.vatTarget.value = this.format(vat)
    this.totalWithVatTarget.value = this.format(totalWithVat)
  }

  format(value) {
    return value.toLocaleString(this.application.element.lang, this.formatOptions)
  }
}
