import { Controller } from 'stimulus'
import dom from 'helpers/dom'

export default class extends Controller {
  connect() {
    if (this.element.querySelector('[data-action][data-confirm]'))
      this.confirmMessage = this.element.querySelector('[data-action][data-confirm]').dataset.confirm
  }

  revert(e) {
    e.currentTarget.dataset.confirm = this.confirmMessage

    if (e.detail[0].message !== undefined) {
      dom.alert('danger', e.detail[0].message)
    }
  }

  destroy(e) {
    this.adaptLayout()

    this.element.remove()

    dom.alert('success', e.detail[0].message)

    // Unselect option with this blob ID in all select with "blob_ids" name
    document.querySelectorAll('select[name*="blob_ids"]').forEach((select) => {
      const option = select.querySelector('[value="' + this.data.get('blob-id') + '"]')

      if (option) {
        option.selected = false
        option.disabled = false

        $(select).selectpicker('refresh')
      }
    })
  }

  adaptLayout() {
    if (this.element.nodeName == 'TR') {
      const tbody = this.element.parentElement
      const table = tbody.parentElement

      if (tbody.rows.length == 1) {
        if (table.parentElement.classList.contains('table-responsive')) {
          table.parentElement.remove()
        } else {
          table.remove()
        }
      }
    }
  }
}
