import { Controller } from 'stimulus'
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import ptLocale from '@fullcalendar/core/locales/pt';
import chroma from 'chroma-js/chroma';
import dom from 'helpers/dom'

export default class extends Controller {
  static targets = ['employee', 'plugin']

  /**
   * Initialize calendar and set some configurations.
   */
  initialize() {
    this.$modal = $('#calendarModal')

    this.currentYear = parseInt(this.element.dataset.year)
    this.currentEmployee = undefined

    this.paramsGroup = this.element.dataset.paramsGroup

    this.calendar = new Calendar(this.pluginTarget, {
      plugins: [interactionPlugin, dayGridPlugin, bootstrapPlugin],
      locale: ptLocale,
      header: {
        left: 'prev next',
        center: 'title',
        right: 'today'
      },
      themeSystem: 'bootstrap',
      height: 'auto',
      eventOrder: 'employeeName',
      // showNonCurrentDates: false,
      validRange: this.getValidRange(this.currentYear)
    })

    if (this.hasEmployeeTarget) {
      this.attachEventRenderCallback()
      this.attachEventDestroyCallback()
      this.attachDateClickCallback()
      this.attachEventClickCallback()

      this.attachFormSubmitEvent()

      this.setEmployeesArray()
      this.setColorPalette()

      this.addExistingEvents()
    }

    this.calendar.render()
  }

  /**
   * Create the array for employee data.
   */
  setEmployeesArray() {
    this.employees = this.employeeTargets.map(element => {
      let employee = JSON.parse(element.dataset.employee)

      employee.events = JSON.parse(element.dataset.events)
      employee.element = element

      return employee
    })

    // Select the first employee with a fake event object
    this.selectEmployee({ currentTarget: this.employeeTarget })
  }

  /**
   * Generate a color palette for employees.
   */
  setColorPalette() {
    const colors = ['#fad96e', '#dc586c', '#91d26d', '#2A4858']
    const count = this.employees.length

    this.palette = chroma.scale(colors).mode('lch').colors(count)

    this.employeeTargets.forEach((element, i) => {
      element.querySelector('.employee-color').style.color = this.palette[i]

      this.employees[i].color = this.palette[i]
    })
  }

  /**
   * Get the valid range for calendar by year.
   * @param {integer} year
   */
  getValidRange(year) {
    return {
      start: year + '-01-01',
      end: year + 1 + '-01-01'
    }
  }

  /**
   * Get URL for create action.
   * @param {integer} employeeId
   */
  getCreateUrl(employeeId) {
    return this.element.dataset.createUrl
      .replace(':employeeId', employeeId)
  }

  /**
   * Get URL for destroy action.
   * @param {integer} employeeId
   * @param {integer} id
   */
  getDestroyUrl(employeeId, id) {
    return this.element.dataset.destroyUrl
      .replace(':employeeId', employeeId)
      .replace(':id', id)
  }

  /**
   * Find an event by employee ID and event date.
   * @param {integer} employeeId
   * @param {Date} eventDate
   */
  getEvent(employeeId, eventDate) {
    eventDate.setHours(0, 0, 0, 0)

    return this.calendar.getEvents().find(e =>
      e.extendedProps.employeeId == employeeId && e.start.getTime() === eventDate.getTime()
    )
  }

  /**
   * Get form data to use with Rails ajax.
   * @param {object} event
   */
  getFormData(event) {
    const data = new FormData()

    if (event.date !== undefined) {
      data.append(this.paramsGroup + '[date]', event.date)
    }

    if (event.dates !== undefined) {
      for (var i = 0; i < event.dates.length; i++) {
        data.append(this.paramsGroup + '[dates][]', event.dates[i])
      }
    }

    if (this.$modal.length > 0) {
      data.append(this.paramsGroup + '[notes]', event.notes)
      data.append(this.paramsGroup + '[kind]', event.kind)
    }

    return data
  }

  /**
   * Add existing events to calendar from each employee.
   */
  addExistingEvents() {
    this.employees.forEach(employee => {
      employee.events.forEach(event => {
        this.addEvent(employee, event, false)
      })
    })
  }

  /**
   * Handle event.
   * @param {Date} date
   */
  handleEvent(date) {
    const existingEvent = this.getEvent(this.currentEmployee.id, date)
    const dateStr = this.calendar.formatIso(date).substr(0, 10)

    if (existingEvent === undefined) {
      if (this.$modal.length > 0) {
        this.openModal(dateStr)
      } else {
        this.addEvent(this.currentEmployee, { date: dateStr })
      }
    } else {
      this.removeEvent(this.currentEmployee, existingEvent)
    }
  }

  /**
   * Add an event to calendar and update database.
   * @param {object} employee
   * @param {object} event
   * @param {boolean} ajax
   */
  addEvent(employee, event, ajax = true) {
    const existingEvent = this.getEvent(employee.id, new Date(event.date))
    const newEvent = this.calendar.addEvent({
      title: event.title,
      start: event.date,
      end: event.date,
      allDay: true,
      className: 'fc-event-circle',
      color: employee.color,
      extendedProps: {
        id: event.id,
        notes: event.notes,
        kind: event.kind,
        employeeId: employee.id,
        employeeName: employee.name
      }
    })

    // Remove old event if exists
    if (existingEvent && existingEvent.extendedProps.id) {
      this.removeEvent(employee, existingEvent, false)
    }

    // Update counter only if event is new
    if (event.id === undefined) {
      this.updateEventCounter(employee, newEvent.start, 'add')
    }

    if (ajax) {
      Rails.ajax({
        url: this.getCreateUrl(employee.id),
        type: 'post',
        data: this.getFormData(event),
        success: (data) => {
          newEvent.setExtendedProp('id', data.id)
        },
        error: (data) => {
          dom.alert('danger', data.message)

          this.removeEvent(employee, newEvent, false)
        }
      })
    }
  }

  /**
   * Remove an event from calendar and update database.
   * @param {object} employee
   * @param {EventApi} event
   * @param {boolean} ajax
   */
  removeEvent(employee, event, ajax = true) {
    // Ignore if doesn't exist event
    if (event === undefined) {
      return false
    }

    event.remove()

    this.updateEventCounter(employee, event.start, 'remove')

    // Ignore if doesn't exist ID for event
    if (ajax && event.extendedProps.id !== undefined) {
      Rails.ajax({
        url: this.getDestroyUrl(employee.id, event.extendedProps.id),
        type: 'post',
        data: '_method=delete',
        error: (data) => {
          dom.alert('danger', data.message)

          this.addEvent(employee, { id: event.extendedProps.id, date: event.start }, false)
        }
      })
    }
  }

  /**
   * Add multiple events to calendar and update database.
   * @param {object} employee
   * @param {object} event
   */
  addEventBatch(employee, event) {
    // Add each event to calendar without ajax
    for (var i = 0; i < event.dates.length; i++) {
      this.addEvent(employee, {
        title: event.title,
        date: event.dates[i],
        notes: event.notes,
        kind: event.kind
      }, false)
    }

    // Make a unique request with all dates
    Rails.ajax({
      url: this.getCreateUrl(employee.id),
      type: 'post',
      data: this.getFormData(event),
      success: (data) => {
        for (var i = 0; i < event.dates.length; i++) {
          this.getEvent(employee.id, new Date(event.dates[i])).setExtendedProp('id', data.ids[event.dates[i]])
        }
      },
      error: (data) => {
        dom.alert('danger', data.message)

        for (var i = 0; i < event.dates.length; i++) {
          this.removeEvent(employee, this.getEvent(employee.id, new Date(event.dates[i])), false)
        }
      }
    })
  }

  /**
   * Update event counter for the specific employee.
   * @param {object} employee
   * @param {Date} date
   * @param {string} action
   */
  updateEventCounter(employee, date, action) {
    const eventElement = employee.element.querySelector('.event-counter')
    const pastEventElement = employee.element.querySelector('.past-event-counter')

    this.updateCounter(eventElement, action)

    if (pastEventElement !== null && this.isTodayOrPast(date)) {
      this.updateCounter(pastEventElement, action)
    }
  }

  /**
   * Update an element from DOM with text counter.
   * @param {object} element
   * @param {string} action
   */
  updateCounter(element, action) {
    switch(action) {
      case 'add':
        element.textContent = parseInt(element.textContent) + 1
        break;
      case 'remove':
        element.textContent = parseInt(element.textContent) - 1
        break;
    }
  }

  /**
   * Select the current employee by index.
   * @param {object} event
   */
  selectEmployee(event) {
    this.employeeTargets.forEach((element, i) => {
      if (element === event.currentTarget) {
        element.classList.add('active')

        this.currentEmployee = this.employees[i]
      } else {
        element.classList.remove('active')
      }
    })
  }

  /**
   * Append a tooltip on event render.
   */
  attachEventRenderCallback() {
    this.calendar.setOption('eventRender', (info) => {
      let content = `<b>${info.event.extendedProps.employeeName}</b>`

      if (info.event.extendedProps.notes !== undefined) {
        content += `<br>${info.event.extendedProps.notes}`
      }

      $(info.el).popover({
        content: content,
        container: 'body',
        boundary: 'viewport',
        trigger: 'hover',
        html: true
      })
    })
  }

  /**
   * Dispose the tooltip on event destroy.
   */
  attachEventDestroyCallback() {
    this.calendar.setOption('eventDestroy', (info) => {
      $(info.el).popover('dispose')
    })
  }

  /**
   * Add or remove an event on date click.
   */
  attachDateClickCallback() {
    this.calendar.setOption('dateClick', (info) => {
      this.handleEvent(info.date)
    })
  }

  /**
   * Add or remove an event on event click.
   */
  attachEventClickCallback() {
    this.calendar.setOption('eventClick', (info) => {
      this.handleEvent(info.event.start)
    })
  }

  /**
   * Prepare the form to add an event on submit.
   */
  attachFormSubmitEvent() {
    if (this.$modal.length > 0) {
      this.$modal[0].querySelector('form').addEventListener('submit', (e) => {
        e.preventDefault()

        const form = e.currentTarget
        const kindSelect = form.elements[this.paramsGroup + '[kind]']
        const dates = this.getDatesArray(
          new Date(form.elements[this.paramsGroup + '[from_date]'].value),
          new Date(form.elements[this.paramsGroup + '[to_date]'].value)
        )

        form.querySelector('[type=submit]').disabled = true

        this.$modal.modal('hide')

        this.addEventBatch(this.currentEmployee, {
          title: kindSelect.options[kindSelect.selectedIndex].text,
          dates: dates,
          notes: form.elements[this.paramsGroup + '[notes]'].value,
          kind: kindSelect.value
        })
      })
    }
  }

  /**
   * Open the modal for a specific date.
   */
  openModal(date) {
    const form = this.$modal[0].querySelector('form')

    const fromDateInput = form.elements[this.paramsGroup + '[from_date]']
    const toDateInput = form.elements[this.paramsGroup + '[to_date]']

    const onChangeFlatpickr = function(_selectedDates, dateStr, instance) {
      if (instance.input == fromDateInput) {
        toDateInput._flatpickr.set('minDate', instance.input.value)
      }

      if (instance.input == toDateInput) {
        fromDateInput._flatpickr.set('maxDate', instance.input.value)
      }

      if (dateStr == '') {
        instance.setDate(date)
      }
    }

    form.reset()
    form.querySelector('[type=submit]').disabled = false

    fromDateInput._flatpickr.set('onChange', onChangeFlatpickr)
    toDateInput._flatpickr.set('onChange', onChangeFlatpickr)

    fromDateInput._flatpickr.setDate('', true)
    toDateInput._flatpickr.setDate('', true)

    this.$modal[0].querySelector('.modal-title').innerText = this.currentEmployee.name

    this.$modal.modal('show')
  }

  /**
   * Check if date is today or past.
   * @param {Date} date
   */
  isTodayOrPast(date) {
    const today = new Date()

    return date < today || date.toDateString() === today.toDateString()
  }

  /**
   * Get dates array in between two dates.
   * @param {Date} start
   * @param {Date} end
   */
  getDatesArray(start, end) {
    let dates = []
    let current = start

    while (current <= end) {
      dates.push(this.calendar.formatIso(current).substr(0, 10))

      current.setDate(current.getDate() + 1);
    }

    return dates
  }
}
