import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['price', 'vatRateId', 'dropdown']

  initialize() {
    this.$dropdownToggle = $(this.element.querySelector('[data-toggle="dropdown"]'))

    this.dropdownTarget.style.maxHeight = '200px'
  }

  show(event) {
    this.input = event.target
  }

  filter(event) {
    const string = new RegExp(this.normalize(event.target.value), 'i')

    if (!this.dropdownTarget.classList.contains('show')) {
      this.$dropdownToggle.dropdown('toggle')
    }

    this.dropdownTarget.querySelectorAll('.dropdown-item').forEach((item) => {
      if (!this.normalize(item.innerText).match(string)) {
        item.classList.add('d-none')
      } else {
        item.classList.remove('d-none')
      }
    })

    if (this.dropdownTarget.querySelectorAll('.dropdown-item:not(.d-none)').length == 0) {
      this.$dropdownToggle.dropdown('hide')
    } else {
      this.$dropdownToggle.dropdown('update')
    }
  }

  select(event) {
    this.input.value = event.target.innerText

    this.priceTarget.value = event.target.dataset.price
    this.vatRateIdTarget.value = event.target.dataset.vatRateId

    $(this.vatRateIdTarget).selectpicker('refresh')
  }

  normalize(string) {
    const accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž'
    const withoutAccents = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz"

    string = string.split('')

    string.forEach((letter, index) => {
      let i = accents.indexOf(letter)

      if (i != -1) {
        string[index] = withoutAccents[i]
      }
    })

    return string.join('')
  }
}
