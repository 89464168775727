document.addEventListener('turbolinks:load', function () {
  const checkbox = document.getElementById('equal_addresses');

  // If has errors on collapsible fields or the fields are not empty shows collapse element
  if ($('#collapsible-fields').data('valid') == false || $('#collapsible-fields').data('present') == true) {
    $('#collapsible-fields').collapse('show');
  } else {
    $('#collapsible-fields').collapse('hide');
    $('#collapsible-fields-btn').prop('disabled', true);
    $('#collapsible-fields').attr('disabled', true);
  }

  // When checkbox is changed hide the collapsible element
  checkbox && checkbox.addEventListener('change', function () {
    if (this.checked)
      $('#collapsible-fields').collapse('hide');
    else
      $('#collapsible-fields').collapse('show');
  })

  $('#collapsible-fields').on('hidden.bs.collapse', function () {
    checkbox.checked = true;
    $('#collapsible-fields-btn').prop('disabled', true);
    $('#collapsible-fields').attr('disabled', true);
    $('#collapsible-fields') > $('.arrow-head').html('<i class="icon-angle-down fa-sm"></i>');
  })

  $('#collapsible-fields').on('shown.bs.collapse', function () {
    checkbox.checked = false;
    $('#collapsible-fields-btn').prop('disabled', false);
    $('#collapsible-fields').attr('disabled', false);
    $('#collapsible-fields') > $('.arrow-head').html('<i class="icon-angle-down fa-sm"></i>');
    $('#collapsible-fields') > $('.arrow-head') > $('.icon-angle-down').css('transform', 'rotate(180deg)');
  })

  // Sidebar accordion
  const collapse_active = $('.sidebar-accordion .nav-link.active').closest('.collapse');
  collapse_active.collapse('show');

  collapse_active.on('shown.bs.collapse', function () {
    collapse_active.get(0).scrollIntoView({ block: 'center' });
  });

})
